import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import dompurify, { DOMPurifyI } from 'dompurify';

const createDOMPurify = dompurify;

@Injectable({
  providedIn: 'root'
})
export class DomPurifyHelper {

  private readonly temporaryHrefTarget = 'data-temp-href-target';
  private readonly domPurify: DOMPurifyI;

  constructor(@Inject(DOCUMENT) { defaultView }: Document) {
    this.domPurify = createDOMPurify(defaultView!);
  }

  /**
   * These hooks are added to make it possible to have save anchor tags with target _blank.
   * More info: https://github.com/cure53/DOMPurify/issues/317
   */
  public initializeHooks() {
    this.addBeforeSanitizeAttributesHook();
    this.addAfterSanitizeAttributesHook();
  }

  public sanitize(htmlString: string): string {
    return this.domPurify.sanitize(htmlString, {
      ADD_TAGS: ['oembed'],
      ADD_ATTR: ['url']
    });
  }

  private addBeforeSanitizeAttributesHook() {
    this.domPurify.addHook('beforeSanitizeAttributes', node => {
      if (node.tagName === 'A') {
        if (! node.hasAttribute('target')) {
          node.setAttribute('target', '_self');
        }

        if (node.hasAttribute('target')) {
          node.setAttribute(
            this.temporaryHrefTarget,
            node.getAttribute('target') || ''
          );
        }
      }
    });
  }

  private addAfterSanitizeAttributesHook() {
    this.domPurify.addHook('afterSanitizeAttributes', node => {
      if (node.tagName === 'A' && node.hasAttribute(this.temporaryHrefTarget)) {
        node.setAttribute(
          'target',
          node.getAttribute(this.temporaryHrefTarget) || ''
        );

        node.removeAttribute(this.temporaryHrefTarget);

        if (node.getAttribute('target') === '_blank') {
          node.setAttribute('rel', 'noopener');
        }
      }
    });
  }

}
